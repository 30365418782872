<template>
  <div class="accounts">
    <UserAccountTable :headers="headers" :data="list" />
  </div>
</template>

<script>
import UserAccountTable from "@components/DataTable/UserAccountsDataTable.vue";

export default {
  name: "UserAccountsList",
  components: {
    UserAccountTable,
  },
  data() {
    return {
      headers: [
        {
          text: "Access to Account",
          sortable: false,
          value: "accesstoaccount",
        },
        {
          text: "PermissionType",
          sortable: false,
          value: "permissionType",
        },
        {
          text: "Last Login",
          sortable: false,
          value: "lastlogin",
        },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ],
      list: [
        {
          account: {
            account_name: "Account Name",
            permission: "Co-Owner",
            access: "Suspended",
            account_status: "Open",
            accounts_count: 1,
          },
          userName: "Test user",
          email: "test.user@gmail.com",
          is_system_admin: true,
          updated_on: "01/05/2024 12:10",
          status: "Invited",
        },
        {
          account: {
            account_name: "Account Name",
            permission: "Co-Owner",
            access: "Suspended",
            account_status: "Open",
            accounts_count: 1,
          },
          userName: "Test user",
          email: "test.user@gmail.com",
          is_system_admin: true,
          updated_on: "01/05/2024 12:10",
          status: "Invited",
        },
        {
          account: {
            account_name: "Account Name",
            permission: "Co-Owner",
            access: "Suspended",
            account_status: "Open",
            accounts_count: 1,
          },
          userName: "Test user",
          email: "test.user@gmail.com",
          is_system_admin: true,
          updated_on: "01/05/2024 12:10",
          status: "Invited",
        },
        {
          account: {
            account_name: "Account Name",
            permission: "Co-Owner",
            access: "Suspended",
            account_status: "Open",
            accounts_count: 1,
          },
          userName: "Test user",
          email: "test.user@gmail.com",
          is_system_admin: true,
          updated_on: "01/05/2024 12:10",
          status: "Invited",
        },
        {
          account: {
            account_name: "Account Name",
            permission: "Co-Owner",
            access: "Suspended",
            account_status: "Open",
            accounts_count: 1,
          },
          userName: "Test user",
          email: "test.user@gmail.com",
          is_system_admin: true,
          updated_on: "01/05/2024 12:10",
          status: "Invited",
        },
      ],
    };
  },
};
</script>

<style>
.accounts {
  border: 1px solid #eaecf0;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  height: 100%;
}
</style>
