import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import RadioChecked from "@components/inputs/radio/RadioChecked.vue";
import RadioCurrentCheck from "@components/inputs/radio/RadioCurrentCheck.vue";
import RadioCheckOff from "@components/inputs/radio/RadioCheckOff.vue";
import RadioOff from "@components/inputs/radio/RadioOff.vue";
import RadioOn from "@components/inputs/radio/RadioOn.vue";
import exEllipsis from "@components/assets/icons/iconComponents/ExEllipsis.vue";
import exDrag from "@components/assets/icons/iconComponents/ExDrag.vue";
import CheckboxChecked from "@components/assets/icons/iconComponents/CheckboxChecked.vue";
import CheckboxUnChecked from "@components/assets/icons/iconComponents/CheckboxUnChecked.vue";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import ExSpinner from "@components/common/ExSpinner.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        background: "#FFFFFF",
        surface: "#FFFFFF",
        primary: "#7F56D9",
        "primary-darken-1": "#3700B3",
        secondary: "#03DAC6",
        "secondary-darken-1": "#018786",
        error: "#B00020",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
    },
  },
  icons: {
    values: {
      "radio-off": {
        component: RadioOff,
      },
      "radio-on": {
        component: RadioOn,
      },
      "ex-ellipsis": {
        component: exEllipsis,
      },
      "ex-drag": {
        component: exDrag,
      },
      "ex-spinner": {
        component: ExSpinner,
      },
      exRadioChecked: {
        component: RadioChecked,
      },
      exRadioCurrentCheck: {
        component: RadioCurrentCheck,
      },
      exRadioCheckOff: {
        component: RadioCheckOff,
      },
      checkboxOn: {
        component: CheckboxChecked,
      },
      checkboxOff: {
        component: CheckboxUnChecked,
      },
    },
  },
});
