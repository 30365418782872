import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getListOfAccounts = async (page_no, size = 500) => {
  let result = await axios()({
    method: "get",
    url: `${API.ACCOUNTList}`,
    params: { page: page_no, size: size },
  });
  return result;
};

export const saveAccount = async () => {
  try {
    let result = await axios()({
      method: "post",
      url: `${API.ACCOUNTList}`,
    });
    return result;
  } catch (error) {
    console.error("Error fetching account data:", error);
    throw error;
  }
};

export const createAccount = (data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}create/`,
    data: data,
  });
  return result;
};

export const renameAccount = (account_id, data) => {
  let result = axios()({
    method: "patch",
    url: `${API.ACCOUNTList}${account_id}/rename/`,
    data: data,
  });
  return result;
};

export const openAccount = (account_id, data) => {
  let result = axios()({
    method: "put",
    url: `${API.ACCOUNTList}${account_id}/open/`,
    data: data,
  });
  return result;
};

export const testEmail = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/test/email/`,
    data: data,
  });
  return result;
};
export const changeOwner = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/change-owner/`,
    data: data,
  });
  return result;
};
export const reOpenAccount = (account_id) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/re-open/`,
  });
  return result;
};

export const closeAccount = (account_id) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/close/`,
  });
  return result;
};

export const suspendAccount = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/suspend/`,
    data: data,
  });
  return result;
};

export const deleteAccount = (account_id) => {
  let result = axios()({
    method: "delete",
    url: `${API.ACCOUNTList}${account_id}/delete/`,
  });
  return result;
};

export const getUserToken = (user_id) => {
  let result = axios()({
    method: "get",
    url: `https://api.dev.exiqtive.com/api/common/user/${user_id}/token/`,
  });
  return result;
};
