import Vue from "vue";
import Vuex from "vuex";
import vuexPersist from "vuex-persist";
import _ from "lodash";

import isLoading from "./isLoading";
import sidebar from "./sidebar";
import account from "./account";

Vue.use(Vuex);

export const initialStoreModules = {
  isLoading,
  sidebar,
  account,
};

const vuexLocalStorage = new vuexPersist({
  key: "vuex",
  storage: window.localStorage,
});

export default new Vuex.Store({
  modules: _.cloneDeep(initialStoreModules),
  mutations: {
    resetState(state) {
      _.forOwn(initialStoreModules, (value, key) => {
        state[key] = _.cloneDeep(value.state);
      });
    },
  },
  plugins: [vuexLocalStorage.plugin],
});
