export default {
  state: {
    miniSideBar: false,
  },
  mutations: {
    setMiniSidebar(state, sidebar) {
      state.miniSideBar = sidebar;
    },
    resetUserState(state) {
      state.user = null;
    },
  },
  actions: {
    getMiniSidebar({ commit }) {
      commit("setMiniSidebar", true);
    },
    login({ commit }, user) {
      commit("setUser", user);
    },
    logout({ commit }) {
      commit("resetUserState");
      localStorage.clear();
    },
  },
  getters: {
    miniSideBar: (s) => s.miniSideBar,
  },
};
