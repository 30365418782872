import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getListofUser = (account_id) => {
  let result = axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/access/`,
  });
  return result;
};

export const createUser = (data) => {
  let result = axios()({
    method: "post",
    url: `${API.USERList}`,
    data: data,
  });
  return result;
};

export const createAdminUser = (data) => {
  let result = axios()({
    method: "post",
    url: `${API.ADMINUserCreate}`,
    data: data,
  });
  return result;
};

export const manageAppAccess = (account_id, user_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/user/${user_id}/access/`,
    data: data,
  });
  return result;
};

export const getAppAccess = (account_id, user_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/user/${user_id}/access/`,
    data: data,
  });
  return result;
};

export const changePassword = (user_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.USER}${user_id}/password/`,
    data: data,
  });
  return result;
};

export const userStateUpdate = (account_id, user_id, data) => {
  let result = axios()({
    method: "put",
    url: `${API.ACCOUNTList}${account_id}/user/${user_id}/`,
    data: data,
  });
  return result;
};

export const userCheckEmail = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `api/common/account/${account_id}/test/email/`,
    data: data,
  });
  return result;
};

export const getSystemUsers = () => {
  let result = axios()({
    method: "get",
    url: `${API.USERList}`,
    params: { page: 1, size: 500 },
  });
  return result;
};

export const deleteAdminUser = (userId) => {
  let result = axios()({
    method: "delete",
    url: `${API.USERList}${userId}`,
  });
  return result;
};

/*
  data format: { first_name: String, last_name: String }
 */
export const renameAdminUser = (user_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.USERList}${user_id}/rename/`,
    data: data,
  });
  return result;
};

export const cancelInviteUser = (user_id) => {
  let result = axios()({
    method: "post",
    url: `${API.USERList}${user_id}/cancel-invite/`,
  });
  return result;
};
export const blockAdminUser = (user_id) => {
  let result = axios()({
    method: "post",
    url: `${API.USERList}${user_id}/block/`,
  });
  return result;
};

export const unBlockAdminUser = (user_id) => {
  let result = axios()({
    method: "post",
    url: `${API.USERList}${user_id}/unblock/`,
  });
  return result;
};

export const closeUser = (user_id) => {
  let result = axios()({
    method: "post",
    url: `${API.USERList}${user_id}/close/`,
  });
  return result;
};

export const unCloseUser = (user_id) => {
  let result = axios()({
    method: "post",
    url: `${API.USERList}${user_id}/unclose/`,
  });
  return result;
};

export const resendInviteAdminUser = (userId, data) => {
  let result = axios()({
    method: "post",
    url: `${API.USERList}${userId}/resend-invite/`,
    data: data,
  });
  return result;
};

export const resetUserPassword = (data) => {
  let result = axios(false)({
    method: "post",
    url: `api/common/reset-password/`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return result;
};

export const sendNewPasswordData = (data) => {
  let result = axios(false)({
    method: "post",
    url: `api/common/user/reset/password/`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return result;
};
