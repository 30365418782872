<template>
  <div class="subscription-detail">
    <SubscriptionTable
      :headers="headers"
      :data="sanitizedItems"
    ></SubscriptionTable>
  </div>
</template>

<script>
import SubscriptionTable from "@components/SubscriptionTable/SubscriptionTable.vue";
import { getListOfAccounts } from "@/api";
export default {
  name: "SubscriptionView",
  components: {
    SubscriptionTable,
  },
  data() {
    return {
      headers: [
        {
          text: "Account",
          sortable: false,
          value: "account",
        },
        {
          text: "Subscription",
          sortable: false,
          value: "subscription",
        },
        {
          text: "Period",
          sortable: false,
          value: "period",
        },
        {
          text: "Price",
          sortable: false,
          value: "price",
          centerField: true,
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
          centerField: true,
        },
        {
          text: "",
          sortable: false,
          value: "actions",
        },
      ],
      accountItems: [],
    };
  },
  async mounted() {
    try {
      await this.loadAccounts();
    } catch (e) {
      console.log("e: ", e);
    }
  },
  computed: {
    sanitizedItems() {
      return this.accountItems.map((el) => {
        const newEl = {};
        newEl.id = el.id;
        newEl.account = el?.name || "";
        newEl.subscription = el?.subscription || "Unlimited";
        newEl.price = el?.price || 0;
        newEl.period = el?.period || "0";
        newEl.status = el?.status || "draft";
        return newEl;
      });
    },
  },
  methods: {
    async loadAccounts() {
      this.$loader.show();
      const { data } = await getListOfAccounts(1, 500);
      this.accountItems = data.results;
      this.$loader.hide();
    },
  },
};
</script>

<style scoped>
.subscription-detail {
  border: 1px solid #eaecf0;
  padding: 1px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  height: 100%;
}
</style>
