import { render, staticRenderFns } from "./AdminDataTable.vue?vue&type=template&id=07a48c78&scoped=true"
import script from "./AdminDataTable.vue?vue&type=script&lang=js"
export * from "./AdminDataTable.vue?vue&type=script&lang=js"
import style0 from "./AdminDataTable.vue?vue&type=style&index=0&id=07a48c78&prod&lang=css"
import style1 from "./AdminDataTable.vue?vue&type=style&index=1&id=07a48c78&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../admin/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07a48c78",
  null
  
)

export default component.exports