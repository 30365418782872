export default {
  state: {
    accountData: [],
    accountDataLoading: false,
    accountDataCount: 0,
    errorMessage: "",
  },
  mutations: {
    setAccountData(state, accountData) {
      state.accountData = accountData;
    },
    setAccountDataLoading(state, accountData) {
      state.accountDataLoading = accountData;
    },
    setAccountDataCount(state, accountDataCount) {
      state.accountDataCount = accountDataCount;
    },
    setErrorMessage(state, message) {
      state.errorMessage = message;
    },
  },
  actions: {
    async getAccountData({ commit }, value) {
      try {
        commit("setAccountData", value.data.results);
        commit("setAccountDataLoading", true);
        commit("setAccountDataCount", value.data.count);
      } catch {
        commit("setAccountData", []);
        commit("setAccountDataLoading", true);
      }
    },
    async createAccount(payload, { commit }) {
      try {
        await this.$axios.$get("/api/common/account/", payload);
      } catch (error) {
        commit("setErrorMessage", error.message);
      }
    },
  },
  getters: {
    accountData: (s) => s.accountData,
    accountDataLoading: (s) => s.accountDataLoading,
  },
};
