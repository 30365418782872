import axios from "./_axios";
import { API } from "./apiEndPoints";
import store from "../store/index";

export const loginEmailPW = async (params) => {
  let result = await axios(false)({
    method: "post",
    url: API.LOGIN,
    data: params,
  });
  return result;
};

export const getListofAccount = async () => {
  try {
    let result = await axios(true).get(API.ACCOUNTList);
    // let result = await axios(true)({
    //   method: "get",
    //   url: API.ACCOUNTList,
    // });
    store.dispatch("getAccountData", result);
    return result;
  } catch (error) {
    console.error("Error fetching account data:", error);
    // throw error;
  }
};
